<template>
  <div class="static-page">

    <b-container>
      <section class="section text-center">
        <h1 class="h1">Termini e condizioni</h1><br>
        <p>
          <strong>
            SI PREGA DI LEGGERE ATTENTAMENTE QUESTI TERMINI E CONDIZIONI. ESSI<br>
            CONTENGONO IMPORTANTI INFORMAZIONI SUL FUNZIONAMENTO DEL SERVIZIO<br>
            {{ site.brand }} {{ site.payoff }} E SULLE LIMITAZIONI DI RESPONSABILITÀ
          </strong>
        </p>
      </section>

      <section class="section">
        <h2 class="h3 text-center">DEFINIZIONI</h2><br>
        <p>L’espressione <strong>SITO WEB {{ site.brand }} {{ site.payoff }}</strong> indica i software accessibili all’indirizzo <a href="https://booking.vadobay.com/">https://booking.vadobay.com/</a> di proprietà della società Salabam srl che permettono la visualizzazione e la prenotazione di disponibilità di pernotto e servizi turistici.</p>
        <p>I termini <strong>NOI</strong>, <strong>SOCIETÀ</strong> e <strong>{{ site.brand }} {{ site.payoff }}</strong> si riferiscono ad un servizio online fornito da Salabam srl, una società a responsabilità limitata Italiana con sede in Via del Porto 50, 47841 Cattolica, RN, Italia.</p>
        <p><strong>STRUTTURA RICETTIVA</strong> è ogni soggetto fornitore di pernotti e servizi di viaggio.</p>
        <p>Con l’espressione <strong>UTENTE</strong> si intende la persona fisica che visita il Sito web {{ site.brand }} {{ site.payoff }} e usufruisce dei suoi servizi.</p>
        <p>Il <strong>VIAGGIATORE</strong> è un utente del sito {{ site.brand }} {{ site.payoff }} che ha effettuato una Prenotazione.</p>
        <p><strong>PORTALE PARTNER</strong> indica un sito web convenzionato con {{ site.brand }} {{ site.payoff }} e Salabam srl il quale tramite un meccanismo tecnologico di autenticazione sicura, chiamato Single Sign-On (SSO), attribuisce al singolo utente un credito virtuale riconosciuto da {{ site.brand }} {{ site.payoff }} e spendibile sul sito {{ site.brand }} {{ site.payoff }}.</p>
        <p>Il termine <strong>PRENOTAZIONE</strong> indica l’operazione con la quale l’Utente prenota un pernotto tramite i servizi online forniti dal Sito Web {{ site.brand }} {{ site.payoff }} utilizzando una qualsiasi forma di pagamento proposta da {{ site.brand }} {{ site.payoff }} incluso il pagamento tramite il Credito personale a lui messo a disposizione, sul Portale Partner, e riconosciuto ed accettato come strumento di pagamento da {{ site.brand }} {{ site.payoff }}.</p>
        <p>Il termine <strong>DISPONIBILITA’</strong> indica le proposte di pernottamento e servizi turistici visualizzabili e prenotabili sul Sito Web {{ site.brand }} {{ site.payoff }}. Le disponibilità sono proposte dal Sito Web {{ site.brand }} {{ site.payoff }} in tempo reale, nel momento di utilizzo del Sito stesso da parte dell’Utente. Le Disponibilità vengono fornite dagli albergatori o fornitori di servizi turistici direttamente all’Utente/Viaggiatore e non vengono fornite da Booking.com e VadoBay (Salabam srl) i quali agiscono esclusivamente da facilitatori. Booking.com B.V. e VadoBay (Salabam srl) non possiedono, gestiscono, operano o controllano (né direttamente, né indirettamente, né indipendentemente, né in collaborazione con altre parti) alcuna struttura ricettiva. Le strutture ricettive esonerano Booking.com B.V. e VadoBay (Salabam srl) da ogni responsabilità relativamente alla qualità dei servizi di alloggio forniti.</p>
        <p>L’espressione <strong>NUMERO PRENOTAZIONE</strong> indica il riferimento univoco della prenotazione di un pernottamento ed è incluso nel documento inviato via email all’Utente a seguito della sua Prenotazione e recante anche il riepilogo dettagliato dei servizi prenotati.</p>
      </section>

      <section class="section">
        <h2 class="h3 text-center">INDICAZIONI GENERALI</h2><br>
        <p><strong>L’utilizzo di questo sito internet è vincolato all’accettazione senza riserve dei termini, condizioni ed avvertenze ivi contenuti, in vigore al momento della prenotazione. Qualsiasi utilizzo da parte dell’utente o da parte nostra delle informazioni relative al conto personale ed alle prenotazioni effettuate tramite il nostro sito è soggetto ai seguenti termini, condizioni e notifiche. L’utilizzo del sito costituisce la sottoscrizione a tutti i summenzionati termini, condizioni ed avvertenze.</strong></p>
        <p>L’utilizzo del sito è altresì soggetto ai termini e alle condizioni applicate da Booking.com B.V. reperibili al seguente indirizzo : <a href="https://www.booking.com/content/terms.it.html">https://www.booking.com/content/terms.it.html</a></p>
        <p><strong>Se l’utente non accetta tali termini e condizioni non è autorizzato ad utilizzare il sito web. Per ogni controversia sarà esclusivamente competente il Foro di Rimini.</strong></p>
      </section>

      <section class="section">
        <h2 class="h3 text-center">FUNZIONAMENTO E SERVIZI {{ site.brand }} {{ site.payoff }}</h2><br>
        <p>Il Sito Web {{ site.brand }} {{ site.payoff }} permette all’Utente la prenotazione di pernotti e servizi turistici. Il Sito Web {{ site.brand }} {{ site.payoff }} propone all’Utente disponibilità reali e immediatamente prenotabili ottenute dinamicamente da Booking.com B.V..</p>
        <p>VadoBay agisce come interfaccia tra l'Utente e le Strutture ricettive. Quando l'Utente effettua una prenotazione per un Servizio di viaggio usando VadoBay, egli conclude un contratto con il relativo Fornitore dei servizi di viaggio o Struttura ricettiva per tale Servizio.</p>
        <p>L’utente riconosce ed accetta il fatto che {{ site.brand }} {{ site.payoff }} (Salabam srl) eroghi un servizio a fronte di adeguata remunerazione del servizio stesso. Il servizio remunerato è:</p>
        <ul>
          <li>un servizio di prenotazione di pernotti e servizi turistici agevolato da un sistema che permette all’Utente di effettuare in totale libertà ricerche su un elevato numero di destinazioni turistiche, su date e numero di partecipanti</li>
          <li>un servizio che permette a terzi (aziende o organizzazioni) di gestire i loro piani di Welfare Aziendale, o parte di questi, operazioni di promozione commerciale, piani di regalistica, di incentivazione e loyalty.</li>
          <li>un servizio di assistenza clienti gratuito, attivabile tramite telefono, email, webchat, instant messaging</li>
          <li>un servizio ridondante di verifica diretta di ciascuna prenotazione effettuata</li>
        </ul>
        <p>A seguito della prenotazione l'Utente riceverà all'indirizzo email comunicato al momento della prenotazione stessa la conferma di prenotazione con il relativo Numero di prenotazione.</p>
      </section>

      <section class="section">
        <h2 class="h3 text-center">LIMITAZIONE DI RESPONSABILITA’</h2><br>
        <p>Le informazioni, i software, i prodotti e servizi pubblicati su questo sito possono presentare inaccuratezze o errori tipografici. Le informazioni vengono comunicate a {{ site.brand }} {{ site.payoff }} dalle Strutture ricettive, per il tramite di Booking.com B.V. in via telematica. Per questo motivo le informazioni di disponibilità e le descrizioni dei servizi proposti all’Utente vengono costantemente aggiornate e modificate. {{ site.brand }} {{ site.payoff }} o i rispettivi fornitori possono apportare miglioramenti o modifiche al presente sito in qualsiasi momento. Esaurimento o modifiche delle disponibilità proposte possono verificarsi in qualsiasi momento senza preavviso e senza alcuna responsabilità per {{ site.brand }} {{ site.payoff }}.</p>
        <p>L’inclusione o la messa in vendita di qualsivoglia prodotto e servizio su questo sito non costituisce in alcun modo approvazione né raccomandazione di tali prodotti o servizi da parte di VadoBay (Salabam srl).</p>
        <p>Le classificazioni degli hotel visualizzate sul sito hanno valore puramente orientativo e VadoBay (Salabam srl) non può garantirne l’accuratezza.</p>
        <p>Gli operatori, le strutture ricettive e gli altri fornitori di viaggi o di altri servizi proposti sul sito {{ site.brand }} {{ site.payoff }} sono contraenti indipendenti e non sono agenti né impiegati di Salabam srl. VadoBay (Salabam srl) non è responsabile delle azioni, errori, omissioni, rappresentazioni, garanzie, infrazioni o negligenze di uno dei succitati fornitori, né di lesioni, morte, danni a proprietà personale o spese e danni che risultino dal ricorso a tali fornitori.</p>
        <p>VadoBay (Salabam srl) non è responsabile, né emetterà alcun rimborso, in caso di ritardo, cancellazione, overbooking, sciopero, forza maggiore o altri eventi che non dipendano dal suo controllo diretto e non è responsabile per ogni altra spesa aggiuntiva, omissione, cambio di itinerario o azioni causati da governi o autorità.</p>
        <p>In nessun caso VadoBay (Salabam srl) e i rispettivi fornitori, verranno ritenuti responsabili per qualsiasi sanzione, né per qualsiasi danno diretto, incidentale, specifico o emergente causato da, o in ogni modo connesso a, l’utilizzo di questo sito, o al mancato accesso o accesso ritardato al presente sito.</p>
        <p>VadoBay (Salabam srl) non esclude o limita in alcun modo la propria responsabilità su questioni che non possano essere oggetto di esclusione di responsabilità ai sensi della legge vigente.</p>
      </section>

      <section class="section">
        <h2 class="h3 text-center">LINK A SITI DI TERZI</h2><br>
        <p>Questo sito può contenere link ad altri siti, gestiti da società diverse da Salabam srl. Tali link sono forniti solo per riferimento. Salabam srl non controlla tali siti e non è responsabile del loro contenuto, né dell’utilizzo che ne fa l’Utente. Il fatto che {{ site.brand }} {{ site.payoff }} includa dei link ai siti summenzionati non implica in alcun modo l’approvazione del materiale presente sui siti linkati né sta ad indicare alcuna associazione con gli operatori di detti siti.</p>
      </section>

      <section class="section">
        <h2 class="h3 text-center">SOFTWARE DISPONIBILI SUL SITO</h2><br>
        <p>Qualsiasi software o servizio utilizzabile sul Sito Web {{ site.brand }} {{ site.payoff }} è coperto dal diritto di autore di Salabam srl.</p>
        <p>Senza limitazione a quanto appena dichiarato, la copia o la riproduzione del software su qualsiasi altro server o supporto per ulteriore riproduzione o diffusione è espressamente proibita.</p>
      </section>

      <section class="section">
        <h2 class="h3 text-center">ALTRI TERMINI DI UTILIZZO PER L’APPLICAZIONE {{ site.brand }} {{ site.payoff }}<br> PER DISPOSITIVI PORTATILI</h2><br>
        <p>Non esiste una corrispondenza perfetta tra le funzioni del sito fruite da desktop e quelle fruite da mobile, tablet o altri dispositivi.</p>
      </section>

      <section class="section">
        <h2 class="h3 text-center">RISERVA DEI DIRITTI</h2><br>
        <p>L’utente è consapevole che tutti i diritti di proprietà intellettuale, la proprietà stessa e tutti gli interessi nell’Applicazione e nei Contenuti appartengono a Salabam srl e ai suoi fornitori. Tali diritti sono tutelati da leggi e trattati internazionali. Tutti questi diritti sono riservati.</p>
      </section>

      <section class="section">
        <h2 class="h3 text-center">INFORMAZIONI PER L’UTENTE E PER L’UTILIZZO DELL’APPLICAZIONE</h2><br>
        <p>L’elaborazione delle informazioni sull’Utente è trattata in modo conforme alla nostra <a href="https://www.iubenda.com/privacy-policy/32772449">Informativa sulla privacy</a>. Utilizzando questa Applicazione, l’Utente acconsente a tale elaborazione. Si consiglia pertanto la lettura integrale della nostra Informativa sulla privacy e di quella di Booking.com B.V. <a href="https://www.booking.com/content/privacy.it.html ">https://www.booking.com/content/privacy.it.html</a></p>
      </section>

      <section class="section">
        <h2 class="h3 text-center">PAGAMENTO ONLINE</h2><br>
        <p>Il pagamento del corrispettivo delle singole prenotazioni può avvenire tramite:</p>
        <ul>
          <li>addebito diretto ed istantaneo sul conto welfare dell'utente</li>
        </ul>
      </section>

      <section class="section">
        <h2 class="h3 text-center">VARIAZIONI E CANCELLAZIONI</h2><br>
        <p>Le soluzioni di soggiorno proposte su {{ site.brand }} {{ site.payoff }} sono sia di tipo RIMBORSABILE sia di tipo NON-RIMBORSABILE. A seguito di eventuale cancellazione, il valore della prenotazione non viene riaccreditato sui conti di welfare aziendale ma verranno inviate via email istruzioni su come effettuare una nuova prenotazione di pari valore entro 12 mesi, direttamente sul sito {{ site.brand }} {{ site.payoff }}.</p>
      </section>

      <section class="section">
        <h2 class="h3 text-center">DENUNCE DI VIOLAZIONE DEL COPYRIGHT</h2><br>
        <p>Qualora l’Utente ritenga in buona fede che il materiale ospitato da {{ site.brand }} {{ site.payoff }} violi il proprio copyright, può inviarci, anche tramite un suo rappresentante, una comunicazione scritta che includa le informazioni richieste qui di seguito. {{ site.brand }} {{ site.payoff }} non prenderà in considerazione le richieste dell’Utente non compilate correttamente o incomplete. Nel caso in cui la comunicazione contenga false dichiarazioni circa il fatto che un determinato contenuto o attività costituisce una violazione, l’Utente potrebbe rispondere dei danni.</p>
        <ul>
          <li>Elementi che consentano di identificare chiaramente l’opera protetta dal copyright che sarebbe stato violato</li>
          <li>Elementi che consentano di identificare chiaramente il materiale che costituirebbe violazione dell’opera protetta da copyright, in modo da consentire a {{ site.brand }} {{ site.payoff }} di individuare tale materiale sul sito Web (ad esempio, un collegamento al suddetto materiale)</li>
          <li>Informazioni utili per contattare l’Utente affinché {{ site.brand }} {{ site.payoff }} possa rispondere alla richiesta, inclusi, possibilmente, un indirizzo e-mail e un numero di telefono</li>
          <li>Una dichiarazione con cui l’Utente afferma di “aver ritenuto in buona fede che la pubblicazione del materiale in violazione del copyright non sia autorizzata dal titolare del copyright, da un suo rappresentante o dalla legge"</li>
          <li>Una dichiarazione secondo cui “le informazioni riportate nella notifica sono precise e si dichiara sotto giuramento che la parte che presenta denuncia è autorizzata ad agire in nome del titolare del diritto esclusivo che si presume violato”</li>
          <li>La comunicazione deve essere firmata dalla persona autorizzata ad agire in nome del titolare del diritto esclusivo che si presume violato</li>
        </ul>
        <p>L’Utente può inviare la propria comunicazione per e-mail all’indirizzo <a href="mailto:booking@vadobay.com">booking@vadobay.com</a>.</p>
        <br>
        <br>
        <p>Versione rivista e in vigore dal 02 maggio 2024</p>
        <br>
        <p>©2023 Salabam SRL, Tutti i diritti riservati</p>
      </section>

    </b-container>
  </div>
</template>


<script>
import Vue from "vue";

export default {
  name: 'terms',
  metaInfo: {
    title: 'termini e condizioni',
    meta: [
      { vmid: 'metaDescription', name: 'Description', content: 'Scopri le condizioni del nostro servizio di prenotazione viaggi' },
      { vmid: 'dcDescription', name: 'DC.description', lang: 'it', content: 'Scopri le condizioni del nostro servizio di prenotazione viaggi' },
    ],
  },
  components: {
  },
  methods: {
  },
  data(){
    return {
      site: Vue.prototype.$config.site,
      uppercaseBrand: Vue.prototype.$config.site.brand.toUpperCase(),
      host: process.env.VUE_APP_SITE_HOST,

      isVadobay: true, // compatibilità con vadobay/vivamod, serve solo a pilotare l'elenco dei sistemi di pagamento su questa pag

      //etcEnabled: this.$config.guiSettings.integrations.etc.enabled,
      //scalapayEnabled: (this.$config.guiSettings.integrations.scalapay.enabled)? true : false,
      //promoshoppingEnabled: (this.$config.guiSettings.integrations.promoshopping.enabled)? true : false,

      etcEnabled: false,
      scalapayEnabled: false,
      promoshoppingEnabled: false,
    }
  }
}
</script>

<style scoped lang="scss">

  .static-page {
    .container {
      @include media-breakpoint-up(xl) {
        padding: 0 240px 0;
      }
    }
  }

</style>